import React, { useState, useEffect, useReducer, useRef } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Button, Checkbox } from "@material-ui/core";
import {
  Switch,
  BrowserRouter,
  Route,
  useParams,
  useHistory
} from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getOrders,
  getProducts,
  getWriters,
  getChatMessages,
  getChatMessagesStudent,
  updateOrder,
  updateSubscription,
  sendSms,
  getSubscription,
  createNotificationToWriter,
  getStudentsOrders,
  createChatMessage,
  getStudentStudentProfile,
  sendEmail
} from "../../../../Redux/actions";
import HyperLink from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import Chat from "@mui/icons-material/ChatOutlined";
import AsyncStorage from "@callstack/async-storage";
import Payment from "@mui/icons-material/Payment";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";
import OrderChat from "../../Admin/OrderChat/View";
import OrderChatStudent from "../../Admin/OrderChatStudent/View";
import OrderChatAdminStudent from "../../Admin/OrderChatAdminStudent/View";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import OrderMorePages from "./OrderMorePages";
import OrderManagement from "./OrderManagement";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import firebase from "../../../../global/services/Firebase";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useFilePicker } from "use-file-picker";
import CircularProgress from "@mui/material/CircularProgress";
import Close from "@material-ui/icons/Close";
import { pageCountChoices } from "../../../../global/lists/index.js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalButton } from "react-paypal-button-v2";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}
const OrderDetails = ({
  writers,
  role,
  selectedOrder,
  onClick,
  openChat,
  getChatMessages,
  getChatMessagesStudent,
  unSeenCount,
  loadingCreateOrder,
  errorCreateOrder,
  orderCreated,
  updateOrder,
  products,
  subscriptions,
  updateSubscription,
  sendSms,
  createNotificationToWriter,
  getSubscription,
  getStudentsOrders,
  createChatMessage,
  unSeenCountStudent,
  getStudentStudentProfile,
  studentProfileByEmail,
  sendEmail
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [selectedChat, setSelectedChat] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openOrderMnagementDialog, setOpenOrderMnagementDialog] =
    useState(false);
  const [pageCountValue, setPageCountValue] = useState(0);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showOrderMoreButton, setShowOrderMoreButton] = useState(false);
  const inputRef = useRef();
  const [errorList, setErrorList] = useState([]);

  const initailFormState = {
    options: [],
    options2: [],
    dialogText: "",
    images: [],
    extraPrice: 0
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);

  const [pagesLeft, setPagesLeft] = useState(0);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [value, setValue] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    accept: [
      ".json",
      ".pdf",
      ".docx",
      ".doc",
      ".txt",
      ".ppt" + ".png",
      ".jpeg",
      ".jpg",
      ".xlsx",
      ".zip",
      ".rar",
      "image/*"
    ],

    multiple: false
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
  });

  useEffect(async () => {
    if (filesContent.length > 0) {
      const url = await uploadImageAsync(
        filesContent[0].content,
        filesContent[0].name
      );

      let result2 = [];
      let files = [];

      files.push({
        id: makeid(8),
        name: filesContent[0].name,
        size: parseInt(makeid(6)),
        url: url,
        type: "file"
      });
      setFiles(files);

      form.options2.map((option) => {
        if (
          String(option.name).includes("Upload") ||
          String(option.name).includes("upload")
        ) {
          result2.push({
            ...option,
            required: false,
            files: [
              ...(option.files || []),
              {
                id: makeid(8),
                name: filesContent[0].name,
                size: parseInt(makeid(6)),
                url: url,
                type: "file"
              }
            ]
          });
        } else result2.push(option);
      });

      updateForm({
        key: "options2",
        value: result2
      });
    }
  }, [filesContent]);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const uploadImageAsync = async (uri, name) => {
    setUploading(true);
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const ref = firebase
      .storage()
      .ref("dashboard/orders/" + makeid(15))
      .child(name);
    const snapshot = await ref.put(blob);
    setUploading(false);

    return await snapshot.ref.getDownloadURL();
  };

  useEffect(() => {
    if (role == "admin") {
      getSubscription(selectedOrder?.email);
    }
  }, []);
  useEffect(() => {
    if (role == "student" || role == "admin") {
      if (subscriptions?.length > 0 && selectedOrder) {
        let result = 10;
        selectedOrder?.items?.map((Item) => {
          Item.selectedOptions?.map((option) => {
            if (option?.name == "Page Count") {
              result = result - parseInt(option.value || 0);
            }
          });
        });
        setPagesLeft(result);
        setSubscriptionStatus(subscriptions[0]?.status);
        console.log(
          "subscriptions",
          subscriptions,
          selectedOrder,
          subscriptions[0]?.orderTemplate?.items[0]?.sku ==
            selectedOrder?.items[0]?.sku,
          pagesLeft
        );
        if (
          subscriptions[0]?.orderTemplate?.items[0]?.sku ==
          selectedOrder?.items[0]?.sku
        ) {
          setShowOrderMoreButton(true);
        }
      }
    }
  }, [subscriptions]);

  useEffect(() => {
    initalizeProduct();
  }, [products]);

  const initalizeProduct = () => {
    if (products && selectedOrder) {
      setProductList(
        products?.filter(
          (item) =>
            item.id == 139667022 ||
            item.id == 392168910 ||
            item.id == 408258814 ||
            item.id == 474934693 ||
            item.id == 694315665 ||
            item.id == 694325716
        )
      );

      products?.map((product) => {
        if (product?.id == 392168910) {
          setSelectedProduct(product);
          updateForm({
            key: "options",
            value: product.options
          });
          updateForm({
            key: "options2",
            value: product.options
          });
        }
      });
    }
  };

  useEffect(() => {
    getStudentChat(0);
    getStudentStudentProfile(selectedOrder?.email);
  }, []);
  console.log("studentProfileByEmail", studentProfileByEmail);

  const getStudentChat = (selectedChat) => {
    if (selectedChat==0) {
      getChatMessagesStudent(
        "/chatStudent",
        selectedOrder.id,
        role,
        (data) => {}
      );
    }else{
      getChatMessagesStudent(
        "/chatStudentAdmin",
        selectedOrder.id,
        role,
        (data) => {}
      );
    }
    
  };
  useEffect(() => {
    if (selectedProduct) {
      updateForm({
        key: "options",
        value: selectedProduct.options
      });
      updateForm({
        key: "options2",
        value: selectedProduct.options
      });
    }
  }, [selectedProduct]);

  useEffect(() => {
    let error = [];
    form?.options2?.map((item, index) => {
      if (item.required == true) {
        error.push(item);
      }
      if (
        item.type == "TEXTFIELD" &&
        String(item.name).includes("Date") &&
        (item?.value == "NaN-NaN-NaN" ||
          item?.value == "undefined-NaN-undefined" ||
          String(item?.value).length < 10)
      ) {
        error.push(item);
      }
      if (
        item.type == "TEXTFIELD" &&
        !String(item.name).includes("Date") &&
        item?.value == ""
      ) {
        error.push(item);
      }
      if (
        item.type == "TEXTAREA" &&
        !String(item.name).includes("Optional") &&
        item?.value == ""
      ) {
        error.push(item);
      }
    });
    setErrorList(error);
  }, [form.options2]);
console.log('subscriptionStatus',subscriptionStatus);

  const updateOrderAction = async () => {
    let addMessageChatFlag = 0;
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();

    if (errorList.length > 0) {
      toast.error(errorList[0].name + " is required");
      return;
    } else if (uploadingFile) {
      toast.error("Please, wait until the file is uploaded");
      return;
    }
    const email = await AsyncStorage.getItem("email");
    var text = selectedOrder?.privateAdminNotes;
    var orderEmail = String(text).match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    );
    var writerEmail = orderEmail[orderEmail?.length - 1];

    let newItem = { ...selectedOrder?.items[0] };

    newItem.selectedOptions = form?.options2?.map((item) => {
      if (String(item?.name).toLowerCase().includes("upload")) {
        return {
          name: item?.name,
          nameTranslated: {
            en: item.name
          },
          files: item?.files?.map((item) => {
            return {
              name: item.name,
              id: makeid(8),
              siz: makeid(8),
              url: item.url
            };
          }),
          type: item.type
        };
      } else {
        return {
          name: item?.name,
          nameTranslated: {
            en: item.name
          },
          value: item.value,
          valueTranslated: {
            en: item.value
          },
          valuesArray: [item.value],
          selections: [
            {
              selectionTitle: item.value,
              selectionModifier: 0,
              selectionModifierType: "ABSOLUTE"
            }
          ],
          type: item.type
        };
      }
    });
    // newItem.selectedOptions[0] = selectedOrder?.items[0].selectedOptions[0];
    newItem.name = selectedProduct.name + " (Extra Pages)";
    newItem.productId = selectedProduct.id;
    newItem.imageUrl = selectedProduct.url;
    newItem.productPrice = selectedProduct.price;
    newItem.sku = selectedProduct.sku;
    selectedOrder.items?.push(newItem);
    console.log("selectedOrderss", selectedOrder);
    let file = null;
    form?.options2?.map((item) => {
      if (String(item?.name).toLowerCase().includes("upload")) {
        if (item?.files?.length > 0) {
          file = { name: item?.files[0].name, url: item?.files[0].url };
        }
      }
    });
    updateOrder(
      selectedOrder?.id,
      selectedOrder.items,
      selectedOrder.total,
      (data) => {
        console.log("datadatadata", data);
        if (data?.updateCount == 1) {
          sendSms(
            `Hello, Additional pages have been requested for order #${selectedOrder?.id}. Writer ${writerEmail} has been notified. Please click the link below to review the order https://ppwlocker.com/dashboard/orders.`
          );
          sendEmail({
            subject: `Order #${selectedOrder.id} update from the student`,
            to: "paperwriter20@gmail.com",
            order: selectedOrder,
            title: "Additional pages",
            description: `Additional pages have been requested for order #${selectedOrder?.id}`,
            fileUrl: "",
            fileName: ""
          });
          createNotificationToWriter(
            selectedOrder.id,
            "",
            email,
            "student",
            selectedOrder?.privateAdminNotes,
            writerEmail,
            "Additional pages",
            `Additional pages have been requested for order #${selectedOrder?.id}`,
            null,
            new Date().toISOString()
          );
          toast.success(`Your order with ID (${selectedOrder?.id}) has been updated 
          successfully!`);
          setOpenDialog(false);
          setPagesLeft(pagesLeft - pageCountValue);
          //window.location.reload();
          initalizeProduct();
          getStudentsOrders(email, "", "", "");
          if (addMessageChatFlag == 0) {
            addMessageChatFlag = 1;
            createChatMessage(
              "/chatStudent",
              selectedOrder.id,
              "name",
              email,
              "student",
              "",
              "",
              `Hello, Additional pages have been requested for order #${selectedOrder?.id}`,
              file,
              new Date().toDateString() + " - " + time
            );
          }
        }
      },
      (error) => {
        toast.error(error, selectedOrder.items);
      }
    );
  };
  console.log(
    "pagesLeft",
    selectedOrder.email,
    showOrderMoreButton,
    pagesLeft,
    subscriptionStatus
  );
  let attachments = [];
  selectedOrder?.items?.map((item, index) => {
    item?.selectedOptions?.map((option) => {
      if (String(option.name).includes("Upload")) {
        option?.files?.map((file, index) => {
          attachments.push(file);
        });
      }
    });
  });

  function getUnique(arr) {
    let mapObj = new Map();

    arr.forEach((v) => {
      let prevValue = mapObj.get(v.size + v.name);
      if (!prevValue) {
        mapObj.set(v.size + v.name, v);
      }
    });
    return [...mapObj.values()];
  }
  console.log("errorListerrorList", errorList);

  const paymentView = () => {
    if (pageCountValue > pagesLeft) {
      if (errorList?.length > 0 || uploading) {
        return (
          <PayPalScriptProvider
            options={{
              clientId:
                "AbWGT636RcnDtFc_Hj7scmzUQimPVUew0YW3EWDkvj0p-hfZmkFhDYVr-xrfyRb2SQTfFYwTZkvfDjCh",
              intent: "capture"
              //"AZLBKmrZMp_FA2OBRxK9igfMsuwSwS_nH-UrBj44fpRQ_Oq4m2ouaPT3VdioO1XXElXrUVIa0jJ7KIRg"
            }}
          >
            <PayPalButtons
              fundingSource="paypal"
              style={{ layout: "vertical", label: "checkout" }}
              total={5}
              currency="USD"
              env="production"
              disabled={errorList?.length > 0 || uploading}
            />
          </PayPalScriptProvider>
        );
      } else {
        return (
          <PayPalButton
            disabled={true}
            amount={0.1}
            onButtonReady={() => {
              if (errorList.length > 0) {
                toast.error(errorList[0].name + " is required");
                return;
              }
            }}
            shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
            onSuccess={(details, data) => {
              updateOrderAction();
              // OPTIONAL: Call your server to save the transaction
              return fetch("/paypal-transaction-complete", {
                method: "post",
                body: JSON.stringify({
                  orderId: data.orderID
                })
              });
            }}
            options={{
              clientId:
                "AbWGT636RcnDtFc_Hj7scmzUQimPVUew0YW3EWDkvj0p-hfZmkFhDYVr-xrfyRb2SQTfFYwTZkvfDjCh"
            }}
            currency="USD"
          />
        );
      }
    }
  };
  return (
    <Grid
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Button onClick={onClick} className={styles.backButton}>
        <ArrowLeftOutlined className={styles.more} />

        <Typography className={styles.more}>Back</Typography>
      </Button>
      <ToastContainer />
      <Grid
        className={styles.container3}
        container
        justifyContent="flex-end"
        direction="row"
      >
        {(role == "student" || role == "admin") &&
        showOrderMoreButton &&subscriptionStatus!=="CANCELLED"&&
        (pagesLeft > 0 || pagesLeft < 0) ? (
          <Button
            className={styles.menuItem}
            component={motion.button}
            style={{ backgroundColor: Colors.white, width: "250px" }}
            whileHover={{
              backgroundColor: Colors.white,
              transition: { duration: 0.3 }
            }}
          >
            <Typography className={styles.date} style={{ color: Colors.red }}>
              { pagesLeft}{" Pages left"}
            </Typography>
          </Button>
        ) : null}
        {(role == "student" || role == "admin") &&
        showOrderMoreButton &&subscriptionStatus!=="CANCELLED"&&
        (pagesLeft > 0 || pagesLeft < 0) ? (
          <Button
            onClick={() => {
              setOpenDialog(true);
              updateForm({
                key: "options",
                value: selectedProduct.options
              });
              updateForm({
                key: "options2",
                value: selectedProduct.options
              });
              setValue(null);
            }}
            className={styles.menuItem}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.green,
              transition: { duration: 0.3 }
            }}
          >
            <Typography className={styles.messageText}>
              Order More Pages
            </Typography>
          </Button>
        ) : null}
        {role == "admin" ? (
          <Button
            onClick={() => setOpenOrderMnagementDialog(true)}
            className={styles.menuItem}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.green,
              transition: { duration: 0.3 }
            }}
          >
            <Typography className={styles.messageText}>
              Order Management
            </Typography>
          </Button>
        ) : null}
      </Grid>
      <Typography className={styles.idOrder}>
        Order #{selectedOrder.id}
      </Typography>

      <Grid className={styles.container2} container direction="row">
        {role == "admin" && (
          <Typography className={styles.price}>
            ${selectedOrder.total}
          </Typography>
        )}

        <Typography className={styles.date}>
          {new Date(
            new Date(selectedOrder?.createDate).toLocaleString("en-US", {
              timeZone: "America/New_York"
            })
          )
            .toString()
            .substr(0, 24)}
        </Typography>
      </Grid>

      <Grid className={styles.container2} container direction="row">
        <Grid item direction="column">
          <Typography className={styles.orderStatus}>
            Fulfillment Status
          </Typography>
          <Typography
            className={styles.paymentStatus}
            style={{
              color:
                (selectedOrder.fulfillmentStatus == "PROCESSING" &&
                  Colors.orange) ||
                (selectedOrder.fulfillmentStatus == "DELIVERED" &&
                  Colors.green) ||
                (selectedOrder.fulfillmentStatus == "AWAITING_PROCESSING" &&
                  Colors.red)
            }}
          >
            {selectedOrder.fulfillmentStatus}
          </Typography>
        </Grid>

        {role == "admin" && (
          <Grid style={{ marginLeft: "50px" }} item direction="column">
            <Typography className={styles.orderStatus}>
              Payment Status
            </Typography>
            <Typography className={styles.paymentStatus}>
              {selectedOrder.paymentStatus}
            </Typography>
          </Grid>
        )}
      </Grid>

      {role == "admin" && (
        <Grid className={styles.container2} container direction="row">
          <Grid item direction="column">
            <Typography className={styles.orderStatus}>Customer</Typography>
            <Typography className={styles.email}>
              {selectedOrder.email}
            </Typography>
          </Grid>
        </Grid>
      )}

      {role == "admin" && selectedOrder?.paymentMethod && (
        <Grid className={styles.container2} container direction="row">
          <Payment color={Colors.blue} fontSize={"18px"} />{" "}
          <Typography className={styles.Paypal}>
            {" " + selectedOrder?.paymentMethod}
          </Typography>
        </Grid>
      )}
      {role == "admin" && selectedOrder?.discountCoupon && (
        <Grid className={styles.container2} container direction="row">
          <Grid item direction="column">
            <Typography className={styles.orderStatus}>
              Discount coupon
            </Typography>
            <Typography className={styles.email}>
              "{selectedOrder.discountCoupon.code}"{" "}
              {selectedOrder.discountCoupon.discount}{" "}
              {selectedOrder.discountCoupon.discountType == "PERCENT"
                ? "%"
                : "USD"}
            </Typography>
          </Grid>
        </Grid>
      )}
      {selectedOrder?.items?.map((item, index) => {
        return (
          <Grid
            key={JSON.stringify()}
            className={styles.container2}
            container
            direction="row"
          >
            <Avatar variant="rounded" src={item.imageUrl} />
            <Grid item style={{ marginLeft: "10px" }}>
              <Typography className={styles.orderStatus}>
                {item?.name}
              </Typography>

              {item?.selectedOptions?.map((option) => {
                if (!String(option.name).includes("Upload")) {
                  return (
                    <Grid
                      container
                      direction="row"
                      style={{ marginTop: "10px" }}
                    >
                      <Typography className={styles.name}>
                        {option.name} :{" "}
                      </Typography>
                      <Typography className={styles.value}>
                        {option.value}
                      </Typography>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>
        );
      })}
      <Grid className={styles.container2}>
        <Typography className={styles.orderStatus}>Attachments</Typography>

        {getUnique(attachments)?.map((file, index) => {
          return (
            <Grid key={index + " "} container style={{ marginTop: "5px" }}>
              <a className={styles.url} href={file.url} target="_blank">
                {file.name}
              </a>
            </Grid>
          );
        })}

        {selectedOrder?.orderExtraFields?.map((file, index) => {
          return (
            <Grid key={index + " "} container style={{ marginTop: "5px" }}>
              <a className={styles.url} href={file.value} target="_blank">
                {file.title}
              </a>
            </Grid>
          );
        })}
      </Grid>

      {selectedOrder?.orderComments && (
        <Grid className={styles.container2} container direction="row">
          <Typography className={styles.name}>Order Comment: </Typography>
          <Typography className={styles.value}>
            {selectedOrder?.orderComments}
          </Typography>
        </Grid>
      )}

      {(role == "admin" || role == "manager") &&
        selectedOrder?.privateAdminNotes && (
          <Grid className={styles.container2} container direction="row">
            <Typography className={styles.name}>Note for staff: </Typography>
            <Typography className={styles.value}>
              {selectedOrder?.privateAdminNotes}
            </Typography>
          </Grid>
        )}
      {role != "student" ? (
        <Grid container direction="row">
          <Button
            onClick={() => setSelectedChat(0)}
            className={styles.message}
            style={{
              backgroundColor: selectedChat == 0 ? Colors.blue : Colors.grey
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.black,
              transition: { duration: 0.3 }
            }}
          >
            <Chat className={styles.messageIcon} />

            <Typography className={styles.messageText}>
              {role == "writer" ? "Talk to the admin" : "Talk to the writer"}
            </Typography>

            <Typography className={styles.ChatHead}>
              {unSeenCount || 0}
            </Typography>
          </Button>

          <Button
            onClick={() => {
              setSelectedChat(1);
            }}
            className={styles.message}
            style={{
              backgroundColor: selectedChat == 1 ? Colors.blue : Colors.grey
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.black,
              transition: { duration: 0.3 }
            }}
          >
            <Chat className={styles.messageIcon} />

            <Typography className={styles.messageText}>
              Talk to the student
            </Typography>
            <Typography className={styles.ChatHead}>
              {unSeenCountStudent || 0}
            </Typography>
          </Button>
         {role=='admin'&&
          <Button
            onClick={() => {
              setSelectedChat(2);
            }}
            className={styles.message}
            style={{
              backgroundColor: selectedChat == 2 ? Colors.blue : Colors.grey
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.black,
              transition: { duration: 0.3 }
            }}
          >
            <Chat className={styles.messageIcon} />

            <Typography className={styles.messageText}>
              Student & Writer
            </Typography>
            <Typography className={styles.ChatHead}>
              {unSeenCountStudent || 0}
            </Typography>
          </Button>}
        </Grid>
      ) :  <Grid container direction="row">
      <Button
 onClick={() => {
  setSelectedChat(0);
  getStudentChat(0);
}}        className={styles.message}
        style={{
          backgroundColor: selectedChat == 0 ? Colors.blue : Colors.grey
        }}
        component={motion.button}
        whileHover={{
          backgroundColor: Colors.black,
          transition: { duration: 0.3 }
        }}
      >
        <Chat className={styles.messageIcon} />

        <Typography className={styles.messageText}>
          { "Talk to the writer"}
        </Typography>

        <Typography className={styles.ChatHead}>
          {unSeenCount || 0}
        </Typography>
      </Button>

      <Button
        onClick={() => {
          setSelectedChat(1);
          getStudentChat(1);
        }}
        className={styles.message}
        style={{
          backgroundColor: selectedChat == 1 ? Colors.blue : Colors.grey
        }}
        component={motion.button}
        whileHover={{
          backgroundColor: Colors.black,
          transition: { duration: 0.3 }
        }}
      >
        <Chat className={styles.messageIcon} />

        <Typography className={styles.messageText}>
          Talk to the admin
        </Typography>
        <Typography className={styles.ChatHead}>
          {unSeenCountStudent || 0}
        </Typography>
      </Button>
    </Grid>}
      {role != "student" ? (
        selectedChat == 0 ? (
          <OrderChat selectedOrder={selectedOrder} role={role} />
        ) :selectedChat==1? (
          role=='writer'?
          <OrderChatStudent selectedOrder={selectedOrder} role={role} />
          :
          <OrderChatAdminStudent selectedOrder={selectedOrder} role={role} />
        ):
        <OrderChatStudent selectedOrder={selectedOrder} role={role} />

      ) : (
        selectedChat == 0?
        <OrderChatStudent selectedOrder={selectedOrder} role={role} />
        :
        <OrderChatAdminStudent selectedOrder={selectedOrder} role={role} />

      )}
      <OrderManagement
        open={openOrderMnagementDialog}
        handleClose={() => setOpenOrderMnagementDialog(false)}
      >
        <Grid container className={styles.frame}>
          <iframe
            className={styles.frame}
            src="https://ecwid-addons.s3.amazonaws.com/apps/customizations/17283181/document-upload/2.1/cp/index.html?lang=en#7b2273746f72655f6964223a31373238333138312c226163636573735f746f6b656e223a227365637265745f4e6b625046785832747576436d4a4455663131463246694e5852777a55515844222c227075626c69635f746f6b656e223a227075626c69635f646d4e3950736a7337326573486e736e54536a50647334566469584d4d724568222c22766965775f6d6f6465223a2250414745222c226c616e67223a22656e227d"
            title="Order Management"
          ></iframe>
        </Grid>
      </OrderManagement>
      <OrderMorePages
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
          updateForm({
            key: "options",
            value: selectedProduct.options
          });
          updateForm({
            key: "options2",
            value: selectedProduct.options
          });
          setValue(null);
          setPageCountValue(0);
        }}
        subscribeAction={updateOrderAction}
        isPaymentFree={pageCountValue <= pagesLeft}
        disabled={errorList?.length > 0 || uploading}
      >
        <Grid container className={styles.rightContainer}>
          <Typography className={styles.hint}>
            *You can order ten additional pages outside of your subscription
            using{" "}
            <HyperLink
              target="_blank"
              underline="always"
              href="https://www.apluspaperwriting.com/store#!/10-Page-Subscription-Additional-Pages/p/577591852/category=154267763"
            >
              THIS
            </HyperLink>{" "}
            link.
          </Typography>

          <Grid className={styles.formInput} item>
            <Typography
              className={styles.inputTitle}
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                color: Colors.green
              }}
            >
              Total : ${" "}
              {pageCountValue > pagesLeft
                ? parseFloat((pageCountValue - pagesLeft) * 10)
                : 0}
            </Typography>

            <Typography className={styles.inputTitle}>
              Select Product
            </Typography>

            <Box sx={{ width: "95%", marginTop: "10px" }}>
              <FormControl fullWidth>
                <Typography
                  className={styles.inputTitle}
                  style={{ position: "absolute", left: "10px" }}
                >
                  {selectedProduct?.name}
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //label="Age"
                  // onChange={handleChange}
                >
                  {productList?.map((product, index) => {
                    return (
                      <MenuItem
                        key={product?.id + ""}
                        onClick={async () => {
                          if (product?.id == selectedProduct?.id) {
                            return;
                          }
                          setOpenDialog(false);
                          setSelectedProduct(product);
                          updateForm({
                            key: "options",
                            value: product.options
                          });
                          updateForm({
                            key: "options2",
                            value: product.options
                          });
                          setValue(null);
                          setPageCountValue(0);
                          setTimeout(() => {
                            setOpenDialog(true);
                          }, 500);
                        }}
                      >
                        {product.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {form.options.map((item, index) => {
            if (item.type == "SELECT" || item.name == "Page Count") {
              return (
                <Grid className={styles.formInput} item>
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <Box sx={{ width: "95%", marginTop: "10px" }}>
                    <FormControl fullWidth>
                      <Select
                        tyle={{ width: "300px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          item?.selections &&
                          item?.selections[0]?.selectionTitle
                        }
                        //label="Age"
                        // onChange={handleChange}
                      >
                        {pageCountChoices
                          ?.slice(0, pagesLeft + 10)
                          ?.map((choice, index) => {
                            return (
                              <MenuItem
                                onClick={async () => {
                                  let result = [];
                                  form.options2.map((option) => {
                                    if (item.name == option.name) {
                                      result.push({
                                        ...option,
                                        value: choice.text,
                                        valuesArray: [choice.text],
                                        selections: [
                                          {
                                            selectionTitle: choice.text,
                                            selectionModifier:
                                              choice.priceModifier,
                                            selectionModifierType:
                                              choice.priceModifierType
                                          }
                                        ],
                                        required: false
                                      });
                                    } else result.push(option);
                                  });
                                  setPageCountValue(index + 1);
                                  updateForm({
                                    key: "options2",
                                    value: result
                                  });
                                }}
                                value={
                                  choice.text +
                                  (choice.priceModifier != 0
                                    ? " (+$" + choice.priceModifier + ")"
                                    : "")
                                }
                              >
                                {choice.text +
                                  (choice.priceModifier != 0
                                    ? index + 1 > pagesLeft
                                      ? " (+$" +
                                        (index + 1 - pagesLeft) * 10 +
                                        ")"
                                      : ""
                                    : "")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              );
            } else if (item.type == "RADIO") {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <FormControl>
                    <FormLabel
                      style={{
                        marginTop: "20px",
                        color: "black",
                        fontFamily: "Spartan",
                        fontWeight: "bold",
                        fontSize: "16px"
                      }}
                      id="demo-radio-buttons-group-label"
                    >
                      {item.name}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      {item?.choices?.map((choice, index) => {
                        return (
                          <FormControlLabel
                            style={{
                              fontFamily: "Spartan"
                            }}
                            key={JSON.stringify(choice)}
                            value={
                              choice.text +
                              (choice.priceModifier != 0
                                ? " (+$" + choice.priceModifier + ")"
                                : "")
                            }
                            control={
                              <Radio
                                checked={
                                  item?.selections &&
                                  choice.text ==
                                    item?.selections[0]?.selectionTitle
                                }
                                onClick={async () => {
                                  let result = [];
                                  form.options2.map((option) => {
                                    if (item.name == option.name) {
                                      result.push({
                                        ...option,
                                        value: choice.text,
                                        valuesArray: [choice.text],
                                        selections: [
                                          {
                                            selectionTitle: choice.text,
                                            selectionModifier:
                                              choice.priceModifier,
                                            selectionModifierType:
                                              choice.priceModifierType
                                          }
                                        ],
                                        required: false
                                      });
                                    } else result.push(option);
                                  });

                                  updateForm({
                                    key: "options2",
                                    value: result
                                  });
                                }}
                              />
                            }
                            label={choice.text}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              );
            } else if (
              item.type == "TEXTFIELD" &&
              !String(item.name).includes("Date")
            ) {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <TextField
                    ref={inputRef}
                    id="outlined-basic"
                    variant="outlined"
                    style={{ marginTop: "10px", width: "95%" }}
                    value={item?.value}
                    onChange={(event) => {
                      event.preventDefault();

                      let result = [];
                      form.options2.map((option) => {
                        if (item.name == option.name) {
                          result.push({
                            ...option,
                            value: event.target.value,
                            valuesArray: [event.target.value],
                            required: false
                          });
                        } else result.push(option);
                      });

                      updateForm({
                        key: "options2",
                        value: result
                      });
                      inputRef.current.focus();
                    }}
                  />
                </Grid>
              );
            } else if (
              item.type == "TEXTFIELD" &&
              String(item.name).includes("Date")
            ) {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={value}
                      onChange={(value) => {
                        let dd = String(value?.getDate()).padStart(2, "0");
                        let mm = String(value?.getMonth() + 1).padStart(2, "0");
                        let yyyy = value?.getFullYear();

                        setValue(value);
                        let result = [];

                        form.options2.map((option) => {
                          if (item.name == option.name) {
                            result.push({
                              ...option,
                              value: yyyy + "-" + mm + "-" + dd,
                              valuesArray: [yyyy + "-" + mm + "-" + dd],
                              required: false
                            });
                          } else result.push(option);
                        });

                        updateForm({
                          key: "options2",
                          value: result
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ marginTop: "10px", width: "95%" }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else if (
              item.type == "DATE" &&
              String(item.name).includes("Date")
            ) {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={value}
                      onChange={(value) => {
                        let dd = String(value.getDate()).padStart(2, "0");
                        let mm = String(value.getMonth() + 1).padStart(2, "0");
                        let yyyy = value.getFullYear();

                        setValue(value);
                        let result = [];

                        form.options2.map((option) => {
                          if (item.name == option.name) {
                            result.push({
                              ...option,
                              value: yyyy + "-" + mm + "-" + dd,
                              valuesArray: [yyyy + "-" + mm + "-" + dd],
                              required: false
                            });
                          } else result.push(option);
                        });

                        updateForm({
                          key: "options2",
                          value: result
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ marginTop: "10px", width: "95%" }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else if (item.type == "SELECT") {
              return (
                <Grid className={styles.formInput} item>
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <Box sx={{ width: "95%", marginTop: "10px" }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          item?.selections &&
                          item?.selections[0]?.selectionTitle
                        }
                        //label="Age"
                        // onChange={handleChange}
                      >
                        {item?.choices?.map((choice, index) => {
                          return (
                            <MenuItem
                              onClick={async () => {
                                let result = [];
                                form.options2.map((option) => {
                                  if (item.name == option.name) {
                                    result.push({
                                      ...option,
                                      value: choice.text,
                                      valuesArray: [choice.text],
                                      selections: [
                                        {
                                          selectionTitle: choice.text,
                                          selectionModifier:
                                            choice.priceModifier,
                                          selectionModifierType:
                                            choice.priceModifierType
                                        }
                                      ],
                                      required: false
                                    });
                                  } else result.push(option);
                                });

                                updateForm({
                                  key: "options2",
                                  value: result
                                });
                              }}
                              value={
                                choice.text +
                                (choice.priceModifier != 0
                                  ? " (+$" + choice.priceModifier + ")"
                                  : "")
                              }
                            >
                              {choice.text +
                                (choice.priceModifier != 0
                                  ? " (+$" + choice.priceModifier + ")"
                                  : "")}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              );
            } else if (item.type == "TEXTAREA") {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>
                  <TextareaAutosize
                    maxRows={7}
                    aria-label={item.name}
                    placeholder={item.name}
                    style={{
                      marginTop: "10px",
                      width: "95%",
                      maxWidth: "95%",
                      minHeight: "130px"
                    }}
                    onChange={(event) => {
                      let result = [];
                      form.options2.map((option) => {
                        if (item.name == option.name) {
                          result.push({
                            ...option,
                            value: event.target.value,
                            valuesArray: [event.target.value],
                            required: false
                          });
                        } else result.push(option);
                      });

                      updateForm({
                        key: "options2",
                        value: result
                      });
                    }}
                  />
                </Grid>
              );
            } else if (item.type == "FILES") {
              return (
                <Grid className={styles.formInput} item>
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>
                  <Button
                    component={motion.button}
                    whileHover={{
                      backgroundColor: Colors.blue,
                      transition: { duration: 0.3 },
                      color: Colors.white
                    }}
                    className={styles.addFiles}
                    style={{
                      backgroundColor: "#000",
                      color: "white",
                      fontFamily: "Spartan",
                      textTransform: "none",
                      fontWeight: "bold",
                      marginTop: "10px"
                    }}
                    onClick={() => {
                      openFileSelector();
                    }}
                  >
                    {uploading ? (
                      <CircularProgress
                        style={{
                          alignSelf: "center",
                          color: Colors.white,
                          width: "30px"
                        }}
                      />
                    ) : (
                      "Add Files"
                    )}
                  </Button>
                  {files?.map((item, i) => {
                    return (
                      <Grid
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        key={JSON.stringify(item)}
                      >
                        <a
                          target="_blank"
                          className={styles.fileName}
                          href={item?.url}
                        >
                          {item?.name}
                        </a>
                        <Button
                          onClick={() => {
                            let result2 = [];
                            setFiles(
                              files.filter((image) => item.id !== image.id)
                            );
                            form.options2.map((option) => {
                              if (
                                String(option.name).includes("Upload") ||
                                String(option.name).includes("upload")
                              ) {
                                result2.push({
                                  ...option,
                                  required: false,
                                  files: option.files.filter(
                                    (image) => item.id !== image.id
                                  )
                                });
                              } else result2.push(option);
                            });

                            updateForm({
                              key: "options2",
                              value: result2
                            });
                          }}
                        >
                          <Close />
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            }
          })}
        </Grid>
        {paymentView()}
      </OrderMorePages>
    </Grid>
  );
};

const mapStateToProps = ({
  orders,
  products,
  writer,
  chat,
  Subscriptions,
  users
}) => {
  return {
    loading: orders.loading,
    error: orders.error,
    orders: orders.orders,
    orderStats: orders.orderStats,
    products: products.products,
    writers: writer.writers,
    unSeenCount: chat.unSeenCount,
    unSeenCountStudent: chat.unSeenCountStudent,
    loadingCreateOrder: orders.loadingCreateOrder,
    errorCreateOrder: orders.errorCreateOrder,
    orderCreated: orders.orderCreated,
    subscriptions: Subscriptions.subscriptions,
    studentProfileByEmail: users.studentProfileByEmail
  };
};

export default connect(mapStateToProps, {
  getChatMessages,
  getOrders,
  getProducts,
  getWriters,
  getChatMessagesStudent,
  updateOrder,
  updateSubscription,
  sendSms,
  getSubscription,
  createNotificationToWriter,
  getStudentsOrders,
  createChatMessage,
  getStudentStudentProfile,
  sendEmail
})(OrderDetails);
