import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Chat from "@mui/icons-material/Chat";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { getChatMessages } from "../Redux/actions";
import { getSubscriptionForAdmin } from "../Redux/actions";

const OrderItem = ({
  order,
  onClick,
  role,
  getChatMessages,
  openChat,
  subscriptions,
  getSubscriptionForAdmin,

  adminPagesLeft,
  adminSubscriptionStatus,
  adminShowOrderMoreButton
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();
  const [unSeenCount, setUnSeenCount] = useState(0);
  const [pagesLeft, setPagesLeft] = useState(0);
  const [showOrderMoreButton, setShowOrderMoreButton] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  useEffect(() => {
    if (role == "admin") {
      getSubscriptionForAdmin(order?.email, (data) => {
        if (data?.length > 0) {
          let result = 10;
          order?.items?.map((Item) => {
            Item.selectedOptions?.map((option) => {
              if (option?.name == "Page Count") {
                result = result - parseInt(option.value || 0);
              }
            });
          });
          setPagesLeft(result);
          setSubscriptionStatus(data[0]?.status);
        }
        let IDs = String(data[0]?.orderTemplate?.id).split("-");
        setShowOrderMoreButton(
          IDs.filter((item) => item == order?.id).length > 0
        );
      });
    }
  }, []);
  useEffect(() => {
    if (role == "student") {
      if (subscriptions?.length > 0 && order) {
        let result = 10;
        order?.items?.map((Item) => {
          Item.selectedOptions?.map((option) => {
            if (option?.name == "Page Count") {
              result = result - parseInt(option.value);
            }
          });
        });
        setPagesLeft(result);
        setSubscriptionStatus(subscriptions[0]?.status);
        let IDs = String(subscriptions[0]?.orderTemplate?.id).split("-");
        setShowOrderMoreButton(
          IDs.filter((item) => item == order?.id).length > 0
        );
      }
    }
  }, [subscriptions]);
console.log('subscriptionStatussubscriptionStatussubscriptionStatussubscriptionStatus',subscriptionStatus,order?.id);

  return (
    <Grid
      component={motion.div}
      whileHover={{
        backgroundColor: Colors.dark + "10",
        transition: { duration: 0.7 },
        scale: 1.01,
        borderColor: Colors.blue + "88",
        borderWidth: "1px"
      }}
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        <Typography className={styles.id}>#{order.id}</Typography>

        <Typography className={styles.date}>
          {new Date(
            new Date(order?.createDate).toLocaleString("en-US", {
              timeZone: "America/New_York"
            })
          )
            .toString()
            .substr(0, 24)}
        </Typography>
      </Grid>

      <Grid style={{ marginTop: "7px" }} container>
        {role == "admin" && (
          <Typography className={styles.paymentStatus}>
            {order.paymentStatus}
          </Typography>
        )}
        <Typography
          className={styles.orderStatus}
          style={{
            color:
              (order.fulfillmentStatus == "PROCESSING" && Colors.orange) ||
              (order.fulfillmentStatus == "DELIVERED" && Colors.green) ||
              (order.fulfillmentStatus == "AWAITING_PROCESSING" && Colors.red)
          }}
        >
          {order.fulfillmentStatus}
        </Typography>
      </Grid>
      {role == "admin" && (
        <Grid container direction="row">
          <Typography className={styles.email}>{order.email}</Typography>
        </Grid>
      )}
      {role != "manager" && (
        <Typography className={styles.price}>${order.total}</Typography>
      )}
      {role == "admin" && order?.paymentMethod && (
        <Grid style={{ marginTop: "7px" }} container direction="row">
          <Payment color={Colors.blue} fontSize={"18px"} />{" "}
          <Typography className={styles.Paypal}>
            {" " + order?.paymentMethod}
          </Typography>
        </Grid>
      )}

      <Grid style={{ marginTop: "7px" }} container direction="row">
        <Grid item style={{ marginLeft: "10px" }}>
          {order?.items?.map((item, index) => {
            return (
              <Grid
                style={{ marginTop: "10px", marginBottom: "15px" }}
                container
                direction="row"
              >
                <Avatar variant="rounded" src={item.imageUrl} />
                <Grid item style={{ marginLeft: "10px" }}>
                  <Typography className={styles.orderStatus}>
                    {item?.name}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: "10px" }} container direction="row">
                  {item.selectedOptions?.map((option) => {
                    if (!String(option.name).includes("Upload")) {
                      return (
                        <Grid
                          container
                          direction="row"
                          key={JSON.stringify(option)}
                        >
                          <Typography className={styles.name}>
                            {option.name} :{" "}
                          </Typography>
                          <Typography className={styles.value}>
                            {String(option.value).length < 50
                              ? option.value
                              : String(option.value).substr(0, 200) + "..."}
                          </Typography>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
            );
          })}
          {order?.orderComments && (
            <Grid style={{ marginTop: "15px" }} container direction="row">
              <Typography className={styles.name}>Order Comment: </Typography>
              <Typography className={styles.value}>
                {order?.orderComments}
              </Typography>
            </Grid>
          )}

          {(role == "admin" || role == "manager") &&
            order?.privateAdminNotes && (
              <Grid style={{ marginTop: "15px" }} container direction="row">
                <Typography className={styles.name}>
                  Note for staff:{" "}
                </Typography>
                <Typography className={styles.value}>
                  {order?.privateAdminNotes}
                </Typography>
              </Grid>
            )}
        </Grid>
      </Grid>

      {role != "student" && unSeenCount && (
        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.blue,
            transition: { duration: 0.3 },
            color: Colors.white
          }}
          className={styles.chat}
          //onClick={openChat}
        >
          <Typography className={styles.ChatHead}>{unSeenCount}</Typography>
        </Button>
      )}
      {(role == "student" || role == "admin") &&
      showOrderMoreButton &&subscriptionStatus!=="CANCELLED"&&
      (pagesLeft > 0 ) ? (
        <div
          className={styles.pagesLeft}
          //onClick={openChat}
        >
          <Typography
            className={styles.orderStatus}
            style={{ color: Colors.red }}
          >
            {pagesLeft} Pages left
          </Typography>
        </div>
      ) : null}

      {(role == "student" || role == "admin") &&
      showOrderMoreButton &&
      (pagesLeft > 0) ? (
        <div
          className={styles.pagesLeft}
          //onClick={openChat}
        >
          <Typography
            className={styles.orderStatus}
            style={{ color: Colors.red }}
          >
            {pagesLeft} Pages left
          </Typography>
        </div>
      ) : null}
      <Button
        component={motion.button}
        whileHover={{
          backgroundColor: Colors.blue,
          transition: { duration: 0.3 },
          color: Colors.white
        }}
        className={styles.moreItem}
        onClick={onClick}
      >
        <Typography className={styles.more}>Details</Typography>
        <ArrowRightAlt className={styles.more} />
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.white,
    position: "relative",
    textTransform: "none",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    border: "1px solid #30374500",
    width: window.innerWidth - 280
  },

  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh"
    }
  },
  name: {
    fontSize: "11px",
    fontFamily: "Spartan",
    color: Colors.black,
    width: "220px",
    fontWeight: "bold"
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px"
  },

  paymentStatus: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px"
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "12px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  chat: {
    border: "1px solid " + Colors.blue,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    color: Colors.blue,

    borderRadius: "20px",
    textTransform: "none",
    position: "absolute",
    right: "110px",
    top: "10px"
  },

  pagesLeft: {
    fontFamily: "Spartan",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    textTransform: "none",
    position: "absolute",
    right: "110px",
    top: "15px"
  },
  ChatText: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold"
  },
  ChatHead: {
    padding: "2px",
    borderRadius: "7px",
    backgroundColor: Colors.red,
    color: Colors.white,
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",
    marginLeft: "3px",
    textAlign: "center",
    justifyContent: "center",
    minWidth: "15px"
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    borderRadius: "20px",
    color: Colors.blue,
    textTransform: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {}
  },
  price: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },

  date: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px"
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan"
  }
}));

const mapStateToProps = ({ chat, Subscriptions }) => {
  return {
    loadingGetchatMessages: chat.loadingGetchatMessages,
    subscriptions: Subscriptions.subscriptions
  };
};

export default connect(mapStateToProps, {
  getChatMessages,
  getSubscriptionForAdmin
})(OrderItem);
